import { pick } from "lodash/fp";
import type { GetStaticProps, InferGetStaticPropsType } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import hash from "object-hash";
import { useCallback, useState } from "react";
import { Levels, TopControls } from "@sunblocks/components";
import type { Level } from "@sunblocks/game";
import { areas, levelById, levels } from "@sunblocks/main";
import { useLastLevelOpened } from "src/components/use-last-level-opened";
import { usePersonalBestScores } from "src/components/use-personal-best-scores";
import { trpc } from "src/trpc/client";
import { serverTrpc } from "src/trpc/server";
import { baseUrl } from "src/vercel/env";
export const getStaticProps = (async ctx => {
  const trpc = serverTrpc(ctx);
  await trpc.allDatas.getBestScores.prefetch(undefined);
  return {
    props: {
      firstLevel: levels[0]!,
      trpcState: trpc.dehydrate(),
      areas: areas.map(({
        _id,
        background,
        levels,
        models
      }) => ({
        _id,
        background,
        models,
        levels: levels.map(({
          _id,
          condition,
          models
        }) => ({
          _id,
          condition,
          models
        }))
      })),
      oembedHash: hash({
        area: pick(["background", "models"], areas[0]),
        level: pick(["background", "gridHeight", "gridWidth", "models"], levels[0])
      })
    }
  };
}) satisfies GetStaticProps;
const Page = ({
  areas,
  oembedHash,
  firstLevel: {
    _id: firstLevelId,
    _rev: firstLevelRef
  }
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const getLevelUrl = useCallback(({
    _id: levelId
  }: Pick<Level, "_id">) => `/level/${levelId}`, []);
  const {
    data: bestScores = {}
  } = trpc.allDatas.getBestScores.useQuery(undefined, {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });
  const [{
    personalBestScores,
    previousBestScores
  }, {
    removePreviousBestScore
  }] = usePersonalBestScores(levelById);
  const router = useRouter();
  const [lastLevelOpened] = useLastLevelOpened();
  const [pickedLevel, setPickedLevel] = useState(false);
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="index.page.tsx">
        {[{
        property: "twitter:image"
      }, {
        property: "og:image"
      }].map((props, index) => <meta key={index} {...props} content={`${baseUrl}/api/oembed/level?levelId=${firstLevelId}&_rev=${firstLevelRef}&hash=${oembedHash}`} />)}
      </Head>
      <TopControls className={`transition-opacity duration-1000 ${pickedLevel ? "pointer-events-none opacity-0" : "opacity-100"}`} data-sentry-element="TopControls" data-sentry-source-file="index.page.tsx" />
      <Levels areas={areas} bestScores={bestScores} getLevelUrl={getLevelUrl} lastLevelOpened={lastLevelOpened} personalBestScores={personalBestScores} previousBestScores={previousBestScores} onAnimateLevelState={removePreviousBestScore} onPickedLevel={async () => setPickedLevel(true)} onHiddenDone={async level => router.push(getLevelUrl(level))} data-sentry-element="Levels" data-sentry-source-file="index.page.tsx" />
    </>;
};
export default Page;